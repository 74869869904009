<template>
  <div class="storyOverlay" :class="[side, {active: content.visibility}]">
    <header>
      <h2>{{ content.title }}</h2>
      <img @click="close" src="@/assets/close-icon.svg" alt="Close Info Overlay" />
    </header>
    <content>
      <img class="scribble" v-if="content.svg != ''" :src="require(`@/assets/svg/${content.svg}`)" />
    </content>
  </div>
</template>

<script>
export default {
  name: 'StoryOverlay',
  props: {
    side: {
      type: String,
      required: true
    }
  },
  methods: {
    close () {
      const data = {
        side: this.side,
        content: {
          visibility: false
        }
      }
      this.$store.dispatch('setStoryOverlay', data)
    }
  },
  computed: {
    content: {
      get () {
        return this.$store.getters.storyOverlay(this.side)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  ::-webkit-scrollbar {
      width: 0;
      background: transparent;
  }

* {
  --blueRGB: 100 144 221;
}

.storyOverlay {
  position: absolute;
  width: 32vw;
  max-height: 95vh;
  margin: 2vh 2vw;
  background-color: rgb(166 255 212 / 95%);
  border: 0.2vw solid #000;
  top: 0vh;
  transition: all 1s ease-in 0s;
  display: grid;
  grid-template-rows: 5vw 1fr;
  z-index: 15;
}

.storyOverlay.left {
  left: -72vh;
}

.storyOverlay.right {
  right: -72vh;
}

.storyOverlay.left.active {
  left: 14vw;
  transition: left 1s ease-out 0s;
}

.storyOverlay.right.active {
  right: 15vw;
  transition: right 1s ease-out 0s;
}

header {
  position: relative;
  display: flex;
  justify-content: space-between;
}

header > h2 {
  align-items: center;
  border: none;
  padding: 0;
  margin: 0 1.5vw;
}

header > img {
  margin: 1vh 1vw;
}

header > img:hover {
  cursor: pointer;
}

content {
  display: flex;
  align-items: flex-start;
  overflow-y: auto;
}

content > img {
  padding: 2vh 4% 4vh;
  width: 92%;
  /* overflow-y: auto; */
}

h2 {
  display: inline-flex;
  border: 0.2vw solid #000;
  padding: 0.75vh 1.8vw;
  margin: 2.25vh auto 1.5vh;
  font-size: 4.7vh;
}

p {
  font-size: 2.15vh;
  text-align: left;
  line-height: 1.25;
}

</style>
