<template>
    <svg class="navigationArrow button flatShadow" :data-style="style" :style="style" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 234.38 363.58">
      <router-link :to="link">
        <polygon :fill="fill" points="163.88 358.06 164.53 190.91 224.71 191.14 117.91 10.73 9.71 190.3 70.24 190.54 69.59 357.7 163.88 358.06"/>
      </router-link>
    </svg>
</template>

<script>
export default {
  name: 'NavigationArrow',
  props: {
    link: {
      type: String,
      required: true
    },
    position: {
      type: Array,
      required: true
    },
    rotation: {
      type: Number,
      required: true
    },
    color: {
      type: String,
      required: true
    }
  },
  computed: {
    style () {
      return `left: ${this.position[0]}vw; top: ${this.position[1]}vh; transform: rotate(${this.rotation}deg);`
    },
    fill () {
      return this.color
    }
  }
}
</script>

<style scoped>
  .navigationArrow {
    position: absolute;
    height: 15vh;
    z-index: 2;
  }

  .navigationArrow polygon {
    stroke-miterlimit: 10;
    stroke-width: 11px;
  }
</style>
