<template>
  <div class="story-submission" :class="{active: isActive}">
    <header>
      <img @click="close" class="button" src="@/assets/close-icon.svg" alt="Close Info Overlay" />
    </header>
    <form v-if="!success" id="story" v-on:submit.prevent="submit()">
      <label for="title">Short Title</label>
      <div id="titleContainer">
        <input type="text" id="title" name="title" v-model="title" :maxlength="maxlength" required />
        <p id="remaining">{{ remaining }}</p>
      </div>
      <label for="story">Enter your Story here</label>
      <textarea name="story" required></textarea>
      <label id="submit">
        <input class="hidden" type="submit" name="submit" value="Submit" />
        <svg class="submit button flatShadow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 308.66 121.17">
          <path d="M29,33.87c52.3-47,174.32-16.73,225.67-4,41.91,10.4,47.25,17.31,48.27,22,4.43,20.3-60.65,49.2-120,59-63.93,10.57-156.72,4.85-172.19-29C.72,59.93,27.39,35.33,29,33.87Z"/>
          <text x="55" y="77">Submit</text>
        </svg>
      </label>
    </form>
    <div v-if="success" id="sendSuccess">
      <p>Thank you for <br>
      sharing your story!</p>
      <p>It will be posted after being approved :)</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StorySubmission',
  data () {
    return {
      maxlength: 15,
      title: '',
      success: false
    }
  },
  methods: {
    async submit () {
      const formStory = document.forms.story
      console.log(formStory)
      const submitRequest = await fetch('https://ayla.raphaeldas.ch/story-submission.php', {
        method: 'post',
        body: new FormData(formStory)
      })
      const response = await submitRequest.json()
      console.log(response)
      this.success = true
    },
    close () {
      this.$store.dispatch('setStorySubmissionFormVisibility', false)
    }
  },
  computed: {
    remaining: {
      get () {
        return this.maxlength - this.title.length
      }
    },
    isActive: {
      get () {
        return this.$store.getters.storySubmissionFormVisibility
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .story-submission {
    position: absolute;
    width: 30vw;
    height: auto;
    margin: 2vh 2vw;
    bottom: 8vh;
    right: -35vw;
    background-color: rgb(255 255 255 / 90%);
    border: 0.2vw solid #000;
    transition: right 1s ease-in 0s;
    z-index: 20;
  }

  .story-submission.active {
    right: 0;
    transition: right 1s ease-out 0s;
  }

  header {
    position: relative;
    display: flex;
    justify-content: flex-end;
    height: 6vh;
    background-color: rgb(var(--blueRGB));
  }

  header > img {
    margin: 1vh 1vw;
  }

  #story {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    text-align: left;
    padding: 0vh 1.5vw 1.5vh;
    font-size: 2vh;
  }

  #story > * {
    margin: 0.5vh 0;
  }

  #story input,
  #story textarea {
    border: 0.2vw solid #000;
    font-size: 3vh;
    padding: 0.75vh;
  }

  #story input#title {
    width: 85%;
  }

  #story > textarea {
    font-size: 2vh;
  }

  #story > textarea[name=story] {
    height: 20vh;
  }

  #titleContainer {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  label#submit {
    text-align: center;
  }

  svg.submit {
    height: 5vh;
    overflow: visible;
  }

  svg.submit path {
    fill: #a6ffd4;
    stroke: #000;
    stroke-miterlimit: 10;
    stroke-width: 7px;
  }

  svg.submit text {
    font-size: 52px;
  }
</style>
